import { JOBS_GET } from '../actions/actionConstants';

export const getJobsInitialState = () => ({
  jobs: [],
});

export default function embed(state = getJobsInitialState(), action) {
  switch (action.type) {
    case JOBS_GET:
      return {
        ...state,
        jobs: action.payload,
      };
    default:
      return state;
  }
}
