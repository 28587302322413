import { combineReducers } from 'redux';
import app from './app';
import blog from './blog';
import jobs from './jobs';

export default combineReducers({
  app,
  blog,
  jobs,
});
