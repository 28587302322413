import { BLOG_GET } from '../actions/actionConstants';

export const getBlogInitialState = () => ({
  posts: [],
});

export default function embed(state = getBlogInitialState(), action) {
  switch (action.type) {
    case BLOG_GET:
      return {
        ...state,
        posts: action.payload,
      };
    default:
      return state;
  }
}
