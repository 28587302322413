import React from 'react';
import supportedLanguages from './supportedLanguages';
import history from '../../history';

const isNode = typeof window === 'undefined';
export const DEFAULT_LANGUAGE = 'de';

// if we are on browser, we assume that the nodeserver already pulled the language data and wrote it inside window
let currentLanguage = isNode ? null : window.App.language;
let currentLanguageData = isNode ? null : window.App.languageData;

export const getCurrentLanguage = () => currentLanguage;

export const getCurrentLanguageFromPathOrNull = path => {
  const langFromUrl = path.split('/')[1];
  for (let i = 0; i < 2; i += 1) {
    if (supportedLanguages[i] === langFromUrl) {
      return langFromUrl;
    }
  }
  return null;
};

export const getCurrentLanguageFromPath = path => {
  const langFromUrl = getCurrentLanguageFromPathOrNull(path);
  return langFromUrl || DEFAULT_LANGUAGE;
};

export const gTl = (id, options = null) => {
  let translation =
    currentLanguageData[id] ||
    `!!!!!!!!!------ MISSING TRANSLATION ------!!!!!!!!! ${currentLanguage}`;
  if (options) {
    const keys = Object.keys(options);
    keys.map(key => {
      translation = translation.replace(
        new RegExp(`{${key}}`, 'g'),
        options[key],
      );
      return translation;
    });
  }
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: translation }} />;
};
export const gTlDiv = id => {
  const translation =
    currentLanguageData[id] ||
    `!!!!!!!!!------ MISSING TRANSLATION ------!!!!!!!!! ${currentLanguage}`;
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: translation }} />;
};
export const gTlPlainText = id => {
  const translation =
    currentLanguageData[id] ||
    `!!!!!!!!!------ MISSING TRANSLATION ------!!!!!!!!! ${currentLanguage}`;
  return translation;
};
export const gTlPlainTextOrNull = id => {
  const translation = currentLanguageData[id] || null;
  return translation;
};

export const gTlObject = id => {
  const translation = currentLanguageData[id] || null;
  return translation;
};

export const getPathWithLanguage = (language, pathname) => {
  if (!isNode) {
    let newPath = `/${language}`;
    pathname.replace(currentLanguage, language);
    if (pathname && pathname.length > 1) {
      const currentLanguageFromPath = getCurrentLanguageFromPathOrNull(
        pathname,
      );
      if (!currentLanguageFromPath) {
        newPath += pathname;
      } else {
        newPath = pathname.replace(currentLanguage, language);
      }
    }
    return newPath;
  }
  return null;
};

const getLanguageData = async language => {
  let data = null;
  switch (language) {
    case 'en':
      data = await import('../../data/i18n/translations/en.json');
      break;
    default:
      data = await import('../../data/i18n/translations/de.json');
      break;
  }
  return data;
};

export const initLanguage = async language => {
  currentLanguage = language;
  currentLanguageData = await getLanguageData(language);
  return currentLanguageData;
};

export const switchLanguage = async (language, pathname) => {
  currentLanguageData = await getLanguageData(language);
  const newPath = getPathWithLanguage(language, pathname);
  history.push(newPath);
  currentLanguage = language;
  return currentLanguageData;
};
