import { APP_CHANGE_LANGUAGE } from '../actions/actionConstants';

export const getAppInitialState = languageFromPath => ({
  language: languageFromPath || 'de',
});

export default function embed(state = getAppInitialState(), action) {
  switch (action.type) {
    case APP_CHANGE_LANGUAGE:
      // set new language
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
}
